.how-we-work {
  background-image: url('../../src/assets/background-how-we-work.svg');
  background-position: left bottom;

  padding-top: 3rem;
  padding-bottom: 5rem;
  margin-bottom: 5rem;

  h2 {
    color: var(--section-head);
    text-align: center;
  }
  p, li { color: var(--font-bg-main); }
  figure {
    max-width: 480px;
    margin: 0 auto;
  }
  .agile-graphic {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 0;
  }
  &-sub li { margin-bottom: 0.5rem; }
  &-sub-title {
    margin-top: 3rem;
    text-align: center;
  }

  @media only screen and (min-width: 410px) {
    &-sub-title {
      width: 83%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media only screen and (min-width: 768px) {
    &-sub {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: 0.5rem;
      width: 80%;
      margin: 0 auto;
    }
  }
}
