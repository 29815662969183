#mfg {
  background-image: url('../../src/assets/background/bg-mfg.jpg');
  background-repeat-x: no-repeat;
  background-position-x: calc(-34vw);
  padding-top: 1.5rem;
  padding-bottom: 4rem;
  margin-top: 1.5rem;
  margin-bottom: 0;
  
  position: relative;
  z-index: 0;
}
.mfg {
  &-content-head h1 {
    font-size: clamp(28px, 7.25vw, 40px);
    max-width: 592px;
  }
  &-content-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 2rem;
  }
  &-processes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));

    &-item {
      padding: 1rem;
      cursor: pointer;
      span { color: lightgray; }
      transition: 0.2s ease-in;
      &:hover {
        background: white;
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      }
      &.highlight {
        background: var(--yellow-1);
        cursor: default;
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        span { color: white; }
      }
    }
    .item-title {
      font-size: 0.9rem;
      max-width: 310px;
    }
    .item-head {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.4rem;
    }
    .item-head img { width: 48px; }
  } // -processes

  &-boards {
    position: relative;
    color: #e4ebec;
    background: rgb(12 12 12 / 56%);
    min-height: 260px;
    padding: 3rem 1.5rem 3rem;
    margin-bottom: 3rem;

    .board {
      display: none;
      h3 {
        font-size: 1.15rem;
        color: var(--yellow-1);
      }
      &.show {
        display: block;
        visibility: visible;
      }
    }
    &-bg {
      position: absolute;
      right: -65px;
      bottom: -80px;
      z-index: -1;
    }
  } // -boards
} // mfg

.iot-offering {
  margin-top: 3rem;
  h1 {
    font-size: clamp(28px, 7.25vw, 40px);
    margin-bottom: 1rem;
  }
  .offer {
    position: relative;
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 65px auto;

    .number {
      font-size: 28px;
      font-family: "PT Serif";
      font-weight: 700;
      color: lightgray;
      align-self: start;
      justify-self: baseline;
      text-align: center;
      height: 100%;
      border-left-width: 6px;
      border-left-style: solid;
      border-image: linear-gradient(to bottom, orange, rgba(0,0,0,0)) 1 100%;
      span {
        display: block;
        transform: rotate(-90deg);
      }
    }
    .title {
      display: flex;
      img { width: 64px; }
      h2 {
        font-size: 1rem;
        color: var(--yellow-2);
      }
    }
    .details {
      grid-column: 2 / -1;
    }
    .stripe {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%23828085' fill-opacity='0.35' fill-rule='evenodd'/%3E%3C/svg%3E");
      position: absolute;
      height: 140px;
      width: 36px;
      top: 90px;
      left: -0.6rem;
    }
    ol {
      margin-left: -1.25rem;
      list-style: disc;
    }
    ol li { margin-bottom: 1rem; }
  } // offer
} // iot-offering

.iot-cases {
  margin-top: 6rem;
  h1 {
    font-size: clamp(28px, 7.25vw, 40px);
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 1.25rem;
    color: var(--yellow-1);
  }

  .case {
    display: flex;
    flex-wrap: wrap;
    .title {
      order: -1;
      max-width: 450px;
    }
    &-screen {
      position: relative;
      order: -1;
    }

    p {
      font-size: 0.9375rem;
      margin-top: 0;
      padding-right: 1rem;
    }
  }
} // .iot-cases

@media only screen and (min-width: 320px) {
  .mfg-boards {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .iot-offering {
    .title {
      img { padding-right: 1.5rem; }
    }
  }
}

@media only screen and (min-width: 768px) {
  #mfg {
    background-position-x: calc(185%);
  }
  .mfg-processes { width: 100%; } 
  .mfg-boards {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 960px) {
  #mfg {
    background-position-x: calc(-34vw);
  }

  .iot-offering .offers {
    .offer:nth-child(2n) { margin-left: 10rem; }
    .offer:nth-child(2n + 1) { margin-right: 10rem; }
  }

  .iot-cases .case {
    display: grid;
    grid-template-columns: 390px minmax(200px, 400px);
    grid-template-columns: 520px auto;
    grid-column-gap: 2rem;
    h2 { grid-column: 1 / -1; }

    &-screen {
      max-width: 420px;
      grid-column: 2/2;
      grid-row: 2/2;

      img {
        position: absolute;
        width: 130%;
      }
    }
  }
}
