.contact {
  margin-bottom: 0;
  padding-bottom: 6rem;
  padding-top: 6rem;
  h2 { margin-top: 0; }

  &-channel {
    display: flex;
    padding-bottom: 0.5rem;
    div:first-child { width: 24px; padding-right: 0.5rem; }
    img { vertical-align: middle; }
  }
}

form {
  margin-top: 3rem;

  .fields-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-column-gap: 1rem;
  }  
  .field {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 1rem 0.15rem;
    margin-bottom: 1rem;
    border: 3px solid transparent;
    border-radius: 11px;
    background: var(--input-bg);
  }
  label {
    color: lightgray;
    font-size: 13px;
    font-weight: 700;
    flex-basis: 100%;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea
  {
    flex-basis: 100%;
    font-size: 1rem;
    padding: 0.25rem 0rem;
    border: var(--input-bg);
    outline: none;
    background: transparent;
    overflow-x: hidden;
  }
  textarea {
    padding-bottom: 1rem;
  }
  .field:nth-child(5) {
    grid-column: 1 / -1;
  }

  button.btn-submit {
    padding: 0.75rem 1.5rem;
    font-size: 20px;
    font-weight: 700;
    background: var(--btn-submit);
    border: none;
    border-radius: 1.5rem;
    color: var(--bg-main);
    cursor: pointer;
    width: 65%;
    min-width: 225px;
  }

  .highlight {
    border: 3px solid var(--input-focus);
  }
} // form

