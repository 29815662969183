.post {
  &-wrapper {
    margin-top: 4rem;
  }
  &-title {
    font-size: 2em;
  }
  &-date {
    color: gray;
    font-size: 0.9375rem;
    margin-top: 0.5rem;
  }

  section { margin-bottom: 2.5rem; }
  p { font-size: 1em; }
  pre, code { font-size: 0.941176em; }
  h2 {
    font-size: 1.6em;
    color: var(--black-1);
  }

  .author {
    margin-top: 3rem;
    padding-top: 3rem;
    border-top: 1px solid lightgray;

    &-name { padding-bottom: 0.5rem; }
    &-twitter {
      display: flex;
      align-items: center;
      img { padding-right: 0.3rem; }
    }
  }

  @media only screen and (min-width: 320px) {
    font-size: 17px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 960px) {
    font-size: 19px;
  }
}
