.technology {
  max-width: 1080px;
  margin: 0 auto;
  margin-bottom: 5rem;
  h2 {
    margin-top: 0;
    text-align: center;
  }
  article {
    margin: 0 auto;
    max-width: 620px;
  }
}
.tech {
  &-logo {
    margin-top: 3.5rem;
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
    justify-items: center;
    align-items: center;
  }
  &-logo-item { max-width: 120px; }
}

@media only screen and (min-width: 320px) {
  .tech-logo { grid-template-columns: repeat(4, 56px); }
  .logo-aws, .logo-gql { grid-column: 1 / 3; }
  .logo-kube { grid-column: 3 / 5; }
}

@media only screen and (min-width: 510px) {
  .tech-logo {
    grid-template-columns: repeat(4, 75px);
  }
}
@media only screen and (min-width: 895px) {
  .tech-logo { grid-template-columns: repeat(7, 75px); }
  .logo-aws { grid-column: 1 / 3; }
  .logo-kube { grid-column: 3 / 5; }
  .logo-gql { grid-column: 5 / 7; }
}
