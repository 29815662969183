.services {
  padding-top: 3rem;
}
.service {
  margin-bottom: 4rem;

  &-icon {
    width: 240px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 320px) {
  .services h2 { text-align: center; }
  .service-title { text-align: center; }
}

@media only screen and (min-width: 768px) {
  .services {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
  }
  .service {
    display: grid;
    grid-column-gap: 3rem;
    grid-row-gap: 1.5rem;
    grid-auto-columns: min-content;
    justify-content: center;

    &-icon {
      grid-row: 1 / 3;
      align-self: center;
    }
    &-title {
      margin: 0;
      text-align: left;
    }
    &-content {
      grid-column:2;
      min-width: 420px;
    }
    p { margin-top: 0; }
  }
}
@media only screen and (min-width: 1100px) {
  .service-icon { width: 290px; }
}
