#iso-29110-header {
  padding: 0.5rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  background-color: var(--bg-main);
  color: #a1a8b1;

  p {
    margin: 0;
  }
  img {
    width: 70px;
    height: 70px;
  }

  @media only screen and (min-width: 320px) {
    font-size: 14px;
    p {
      max-width: 300px;
    }
  }
  @media only screen and (min-width: 568px) {
    p {
      max-width: none;
    }
  }
  @media only screen and (min-width: 960px) {
    font-size: 15px;
    img {
      margin-right: calc(-85px - 1rem);
      width: 85px;
      height: 85px;
    }
  }
}
