html {
  font-family: "Open Sans";
  font-size: 17px;
  color: #040404;
  line-height: 1.35;
  background-color: var(--bg-main);
}

body {
  margin: 0;
  background-color: white;
}

h1,
h2,
h3 {
  font-family: "PT Serif", serif;
}
h1 {
  font-size: 3.1111rem;
  margin: 0;
}
h2 {
  font-size: 2.1904rem;
  color: var(--section-head);
}
h3 {
  font-size: 1.3333rem;
}
img {
  width: 100%;
}

main {
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
}
section {
  margin-bottom: 6rem;
}

h1.th,
h2.th,
h3.th {
  font-family: "Prompt";
  line-height: 1.15;
}
p.th {
  font-family: "Sarabun";
}

.bg-color-main {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--bg-main);
  background-blend-mode: luminosity;
  h2,
  h3 {
    color: var(--section-head);
  }
  article,
  p,
  li {
    color: var(--font-bg-main);
  }
}

.content-box {
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 319px) {
  section {
    padding: 0 0.5rem;
  }
}

@media only screen and (min-width: 320px) {
  section {
    padding: 0 1rem;
  }
  .bg-color-main {
    background-size: 150%;
  }
}

@media only screen and (min-width: 410px) {
  html {
    font-size: 18px;
  }
}

@media only screen and (min-width: 568px) {
  html {
    font-size: 18.5px;
  }
  .content-box {
    max-width: 470px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 19.5px;
  }
  section {
    padding: 0;
  }
  .content-box {
    max-width: 670px;
  }
  .bg-color-main {
    background-size: 100%;
  }
}

@media only screen and (min-width: 960px) {
  html {
    font-size: 21px;
  }
  .content-box {
    max-width: 890px;
  }
}
