#navigation {
  padding-top: 1rem;
  margin-bottom: 0;

  display: flex;
  gap: 1.5rem;
  position: relative;

  nav {
    font-size: clamp(14px, 3vw, 0.9375rem);
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    gap: 1rem;
    row-gap: 0.33rem;
    height: min-content;
    a {
      cursor: pointer;
      text-decoration: none;
      color: #ababab;
    }
  }
  .lang-select {
    display: flex;
    font-size: 0.75rem;
    color: gray;
    text-decoration: none;
    align-items: center;
    span {
      padding-left: 0.5rem;
    }
  }
  .lang-logo {
    width: 24px;
  }

  @media only screen and (min-width: 320px) {
    flex-wrap: nowrap;
    .lang-select {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    .lang-logo {
      width: 20px;
    }
  }
  @media only screen and (min-width: 768px) {
    .lang-select {
      position: initial;
    }
    .lang-logo {
      width: 24px;
    }
  }
  @media only screen and (min-width: 960px) {
    nav {
      font-size: 0.75rem;
      padding: 14px;
    }
  }
}

.brand {
  font-family: "Poppins";
  font-size: 23px;
  font-display: optional;
  padding-bottom: 0.5rem;
  text-decoration: none;
  color: var(--black);
  max-width: 52px;
  &-spec {
    color: var(--orange-1);
    padding-left: 6px;
  }
  @media only screen and (min-width: 440px) {
    max-width: none;
  }
  @media only screen and (min-width: 960px) {
    font-size: 32px;
    padding-bottom: 0;
    width: 90px;
  }
}
