footer {
  padding-top: 5rem;
  padding-bottom: 3rem;
  background: var(--bg-footer);
  color: #c4c5c5;

  .contact-address {
    padding-bottom: 2rem;
    p {
      margin: 0;
      padding-bottom: 0.25rem;
    }
  }
  .company-name, .company-number {
    font-weight: 700;
    color: #e6e6e6;
  }
  .contact-channels {
    .channel {
      display: flex;
      align-items: center;
    }
  }
}

footer > section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 2rem;
  margin-bottom: 0;
}

.bottom-bar {
  background: var(--bg-bottom-bar);
  padding: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--font-bottom-bar);
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
    font-weight: 600;
    color: var(--font-bottom-bar);
  }
  a:after {
    content: '|';
    padding: 5px;
  }
  a:last-child:after {
    content: '';
  }
}

@media only screen and (max-width: 319px) {
  footer > section {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media only screen and (min-width: 410px) {
  footer {
    & > section {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
    .company-name, .company-number {
      font-size: 1.3333rem;
    }
  }
}
