:root {
  --dark                       : #292929;
  --dark-2                     : #424242;
  --dark-3                     : #000d1c;
  --black                      : #050810;
  --black-1                    : #2f2621;
  --white                      : #f2f2f2;
  --white-purple               : #fbf8fb;
  --silver                     : #cbd5e0;
  --lightgray                  : #a2a2a2;
  --green                      : #055f05;
  --yellow-1                   : #ffc837;
  --yellow-2                   : #e4ae12;
  --orange-1                   : #ff8008;
  --orange-2                   : #ff8e2b;
  --brown                      : #564627;

  --section-head               : var(--orange-1);
  --bg-main                    : var(--dark-3);
  --bg-footer                  : var(--black);
  --font-bg-main               : var(--silver);

  --btn-submit                 : var(--orange-2);
  --input-highlight            : var(--yellow-2);
  --input-focus                : var(--orange-2);
  --input-bg                   : var(--white-purple);

  --bg-bottom-bar              : var(--dark);
  --font-bottom-bar            : var(--lightgray);

  --role                       : var(--yellow-2);
}
