:root {
  --hightlight-width-md: 425px;
  --hightlight-width-lg: 550px;
}

.landing {
  position: relative;
  h1 {
    font-size: clamp(31px, 9.25vw, 42px);
    font-weight: normal;
    line-height: 1.125;
    padding-top: 3rem;
    word-spacing: -2px;

    background: linear-gradient(to left, #ffc837, #ff8008);
    background: -webkit-linear-gradient(to left, #ffc837, #ff8008);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h1.en {
    font-weight: 700;
  }

  header .iso-logo {
    position: absolute;
    width: auto;
  }

  .slogan {
    position: relative;
    width: 800px;
    height: 175px;
    display: flex;
    flex-wrap: nowrap;
    margin: 3rem auto 0;
    video {
      width: calc(100% - 5px);
      height: calc(100% - 5px);
      object-fit: cover;
    }
    &-text {
      position: absolute;
      top: 0px;
      font-family: serif;
      font-weight: 900;
      font-size: 110px;
      line-height: 0.85;
      background: white;
      mix-blend-mode: lighten;
      text-align: center;
    }
  }
}

.content-box {
  position: relative;
  height: auto;
}

#intro {
  background-image: url("../../src/assets/background-intro.svg");
  background-position: right top;
}
.intro {
  margin-bottom: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;

  h1 {
    font-size: 1.4444rem;
    color: var(--section-head);
  }
  &-wrap:first-child {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
  }
  &-wrap:last-child {
    margin-bottom: 1rem;
  }

  article {
    padding-bottom: 0.5rem;
    line-height: 1.35;
  }
} // intro

.wave-top-1 {
  position: absolute;
  bottom: -3.5rem;
  width: 100%;
  left: 0;
}

@media only screen and (max-width: 319px) {
  .landing {
    .slogan {
      width: auto;
      height: 100px;
    }
    .slogan-text {
      font-size: 3rem;
    }
    .slogan video {
      display: none;
    }
  }
  .wave-top-1 {
    display: none;
  }
}

@media only screen and (min-width: 320px) {
  .wave-top-1 {
    display: block;
  }
  .landing {
    margin-bottom: 0;
    padding-bottom: 2rem;
    p {
      font-size: 1.2222rem;
      font-weight: 600;
      color: var(--dark-2);
      max-width: var(--hightlight-width-md);
      margin-right: auto;
      margin-left: auto;
    }
    .slogan {
      max-width: 400px;
      width: auto;
    }
    .slogan-text {
      font-family: -apple-system-body;
      font-size: clamp(68px, 14vw, 85px);
      text-shadow: -2px 2px 2px var(--bg-main), -1px 1px 0px var(--bg-main);
      width: 100%;
      height: 100%;
    }
  }
  .intro h1 {
    max-width: var(--hightlight-width-md);
  }
  header .iso-logo {
    width: 75px;
    height: 75px;
    bottom: -92px;
    right: 0;
  }
}

@media only screen and (min-width: 390px) {
  .wave-top-1 {
    display: block;
  }
  .landing {
    text-align: center;
  }
}
@media only screen and (min-width: 410px) {
  header .iso-logo {
    bottom: -95px;
  }
}

@media only screen and (min-width: 475px) {
  .landing {
    padding-bottom: 1rem;
    .slogan {
      width: auto;
      max-width: 600px;
      height: 140px;
    }
    .slogan-text {
      font-size: clamp(68px, 14vw, 85px);
    }
  }
  header .iso-logo {
    bottom: -77px;
  }
}

@media only screen and (min-width: 568px) {
  .landing {
    height: auto;
  }
  .landing .slogan {
    height: 145px;
  }
  header .iso-logo {
    width: 85px;
    height: 85px;
    bottom: -83px;
    right: -35px;
  }
}
@media only screen and (min-width: 768px) {
  .landing {
    text-align: left;
    padding-bottom: 0;
    h1.th {
      max-width: none;
    }
    p {
      margin: 1rem auto 4rem;
      max-width: none;
      padding: 0 3rem;
    }
    header {
      display: block;
      height: auto;
      text-align: center;
    }
    header .iso-logo {
      width: 90px;
      height: 90px;
      bottom: -8px;
      right: -38px;
    }
    .slogan-text {
      font-size: clamp(68px, 14vw, 95px);
    }
    .slogan {
      height: 164px;
    }
  }
}
@media only screen and (min-width: 960px) {
  .landing {
    header {
      max-width: 750px;
    }
    h1 {
      font-size: 2.25rem;
      padding-bottom: 1.25rem;
    }
    p {
      margin: 0 auto 4rem;
    }
    .slogan {
      max-width: 920px;
      height: 220px;
    }
    .slogan-text {
      font-size: clamp(68px, 14vw, 128px);
    }
    header .iso-logo {
      width: 100px;
      height: 100px;
      bottom: -16px;
      right: -16px;
    }
  }
  .intro h1,
  .landing p {
    max-width: var(--hightlight-width-lg);
  }
}
