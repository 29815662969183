#about h2 {
  text-align: center;
}

.leadership {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 375px));
  grid-column-gap: 1rem;
  justify-content: center;

  h2 {
    margin-top: 0;
    grid-column: 1 / -1;
    text-align: center;
  }

  .card {
    margin-bottom: 2rem;
    padding: 1rem 0.65rem;
    border: 1px solid #e6e6e6;
    border-radius: 1.5rem;

    &-header {
      text-align: center;
      .photo {
        width: 150px;
        margin: 0 auto;
        margin-bottom: 0.5rem;
      }
      span {
        display: block;
        padding-left: 0.5rem;
      }
      .name {
        font-weight: 700;
      }
      .role {
        color: var(--role);
        padding-top: 0.25rem;
      }
    }
    &-body {
      width: 93%;
      margin: 0 auto;
      margin-top: 1.65rem;
    }
  }

  @media only screen and (min-width: 580px) {
    font-size: 0.9125rem;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 320px);
  }
}
